
















import { View } from 'client-website-ts-library/plugins';
import { Component, Mixins, Vue } from 'vue-property-decorator';

import Form from '../components/Forms/Form.vue';
import ListingSearch from '../components/ListingSearch.vue';

@Component({
  components: {
    Form,
    ListingSearch,
  },
})
export default class LeaseAppraisal extends Mixins(View) {

}
